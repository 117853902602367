
@import "~styles/theme.css";

.userContainer {
    display: flex;
    /* padding: var(--spacing); */
    clear: both;

    & .userColumn {
        padding-bottom: 0 !important;
    }

    & .userSidebar {
        padding: var(--spacing);
        padding-left: 0;
        padding-top: 0;
        border-right: 1px solid #d4d4d5;

        & .sidebarHeader {
            /* color: #2185d0; */
            margin-left: 10px;
            font-weight: bold;
            margin-top: calc(var(--spacing) * 2);
            margin-bottom: 6px;
            font-size: 18px;
            border-bottom: 1px solid #d4d4d5;
            padding-bottom: calc(var(--spacing) / 2);

            &:first-child {
                margin-top: 0;
            }
        }
    }

    & .userMain {
        padding: var(--spacing);
        padding-right: 0;
        flex-grow: 1;
    }
}
