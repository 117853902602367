
@import "~styles/theme.css";

.navbar {
    height: var(--navbar-height);
    box-shadow: 0 1px 1px rgba(0,0,0,.25) !important;
}

.navbarStarIcon {
    position: absolute;
    right: 22px;
    top: 17px;

    & i {
        outline-color: transparent;
    }
}

.starMenuText {
    text-overflow: ellipsis;
    overflow: hidden;
}

.nodeSearchContainer {
    position: relative;
    display: inline-flex;
    width: 100%;
}
