
@font-face {
  font-family: "Icons";
  src: url("~semantic-ui-css/themes/default/assets/fonts/icons.eot");
  src: url("~semantic-ui-css/themes/default/assets/fonts/icons.eot?#iefix") format("embedded-opentype"), url("~semantic-ui-css/themes/default/assets/fonts/icons.woff2") format("woff2"), url("~semantic-ui-css/themes/default/assets/fonts/icons.woff") format("woff"), url("~semantic-ui-css/themes/default/assets/fonts/icons.ttf") format("truetype"), url("~semantic-ui-css/themes/default/assets/fonts/icons.svg#icons") format("svg");
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}

@font-face {
  font-family: "outline-icons";
  src: url("~semantic-ui-css/themes/default/assets/fonts/outline-icons.eot");
  src: url("~semantic-ui-css/themes/default/assets/fonts/outline-icons.eot?#iefix") format('embedded-opentype'), url("~semantic-ui-css/themes/default/assets/fonts/outline-icons.woff2") format('woff2'), url("~semantic-ui-css/themes/default/assets/fonts/outline-icons.woff") format('woff'), url("~semantic-ui-css/themes/default/assets/fonts/outline-icons.ttf") format('truetype'), url("~semantic-ui-css/themes/default/assets/fonts/outline-icons.svg#icons") format('svg');
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}

@font-face {
  font-family: "brand-icons";
  src: url("~semantic-ui-css/themes/default/assets/fonts/brand-icons.eot");
  src: url("~semantic-ui-css/themes/default/assets/fonts/brand-icons.eot?#iefix") format('embedded-opentype'), url("~semantic-ui-css/themes/default/assets/fonts/brand-icons.woff2") format('woff2'), url("~semantic-ui-css/themes/default/assets/fonts/brand-icons.woff") format('woff'), url("~semantic-ui-css/themes/default/assets/fonts/brand-icons.ttf") format('truetype'), url("~semantic-ui-css/themes/default/assets/fonts/brand-icons.svg#icons") format('svg');
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}
