
@import "./fonts.css";

body {
    overflow-y: hidden;
}

body.fullscreen {
    /* overflow: hidden !important; */
}

small {
    font-style: italic;
    color: #888;
}
