
@import "~styles/theme.css";

.searchList {
    margin-top: var(--spacing);

    & .emptyList {
        position: relative;
        min-height: 50px;
        text-transform: uppercase;
        text-align: center;
        color: #bbb;
    }
}

.headerInfo {
    float: right;
    font-weight: normal;
}

.yearSliderTable {
    display: table !important;
    width: 100% !important;

    & .yearSliderButton {
        display: table-cell !important;

        &:first-child {
            margin-left: 0 !important;
            margin-right: 10px !important
        }

        &:last-child {
            margin-left: 10px !important;
            margin-right: 0 !important
        }
    }

    & .yearSliderInput {
        width: 100%;
        display: table-cell;
        vertical-align: middle;
    }
}

.nameLetterContainer {
    display: flex;
    flex-wrap: wrap;

    & .nameLetterButton {
        padding: 10px !important;

        /* font-size: 0.584rem !important; */
    }
}

.labelButton {
    margin-right: 5px !important;
    margin-bottom: 5px !important;
    cursor: pointer;
}
