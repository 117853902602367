
@import "~styles/theme.css";

.nodeInput {
    /* position: relative; */
    /* width: 100%; */
}

.labelButton {
    cursor: pointer;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25) !important;
}

.nodeInputSearch {
    flex-grow: 1 !important;

    & input {
        border-radius: 4px !important;
    }
}

.nodeInputSearchSelected {
    & input {
        font-weight: bold;
    }
}

.nodeLink {
    cursor: pointer;
}

.nodeCardName {
    font-size: 1.1em !important;
}

.nodeCardTags {
    margin-top: 1em;
}

.nodeCardDescription {
    font-size: 0.8em !important;
}

.nodeCardLabel {
    margin-right: 3px !important;
    margin-bottom: 3px !important;
}

.nodeHeader {
    position: relative;
    margin: 0;
    width: 100%;
    overflow: hidden;
    min-height: 350px;
    z-index: 1;
    vertical-align: bottom;

    & .nodeHeaderBackground {
        position: absolute;
        top: -15px;
        left: -15px;
        right: -15px;
        bottom: -15px;
        z-index: -2;
        display: block;
        background-position: center;
        background-size: cover;
        background-color: #175F95;
        filter: blur(5px);
    }

    & .nodeHeaderBackgroundGradient {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        display: block;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0, 0, 0, 0.9) 85%);
    }

    & .nodeHeaderImage {
        position: absolute !important;
        left: var(--spacing);
        bottom: calc(var(--spacing) + 40px);
        border: 2px solid white;
        border-radius: 1;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
        width: 150px;
        height: 150px;
    }

    & .nodeHeaderText {
        position: absolute !important;
        left: calc((var(--spacing) * 2) + 150px);
        bottom: calc(var(--spacing) + 40px);
        right: var(--spacing);
        text-rendering: optimizelegibility !important;
        text-shadow: 0 0 3px rgba(0, 0, 0, 1) !important;
        color: white;
    }

    & .nodeHeaderTitle {
        font-size: 3em;
        font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif;
        font-weight: 700;
        line-height: 1.28571429em;
    }

    & .nodeHeaderDetails {
        font-weight: 700;
        font-size: 1.2em;
    }

    & .nodeHeaderDescription {
        margin-top: calc(var(--spacing) / 2);
        font-size: 1em;
    }


    & .nodeHeaderLabels {
        position: absolute !important;
        left: var(--spacing);
        top: var(--spacing);
    }

    & .nodeHeaderInformation {
        position: absolute !important;
        right: var(--spacing);
        top: var(--spacing);
        text-align: right;
        text-rendering: optimizelegibility !important;
        text-shadow: 0 0 3px rgba(0, 0, 0, 1) !important;
        color: white;
        font-weight: 600;
        font-size: 0.8em;
    }

    & .nodeHeaderTabContainer {
        position: absolute !important;
        left: var(--spacing);
        right: var(--spacing);
        bottom: 0;

        & .nodeHeaderTabs {
            border-width: 0 !important;
        }
    }
}

.pageContainer {
    border-left: 1px solid #d4d4d5;
    border-right: 1px solid #d4d4d5;

    & .pageSidebar {
        padding: var(--spacing);
        border-left: 1px solid #d4d4d5;
    }
}

:global(.ui.search>.results) {
    width: 100% !important;
}

:global(.ui.search>.results .result .image) {
    width: 18px !important;
    height: 18px !important;
}

:global(.ui.tabular.menu .item) {
    color: white !important;
}

:global(.ui.tabular.menu .active.item) {
    color: black !important;
}
